<script lang="ts" setup>
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
const emit = defineEmits<{ (event: "onTimerEnd"): void }>();

const props = withDefaults(
	defineProps<{
		size?: "lg" | "md";
		timestamp?: string | number | Dayjs;
		format?: string;
		background?: string;
		isBadge?: boolean;
	}>(),
	{ size: "lg", background: "var(--overlay-light)" }
);

const isValidDate = (date: string) => dayjs(date).isValid();

const selectDate = (timestamp: string | number | Dayjs) => {
	// TO DO temporary solution for date formatting
	dayjs.extend(customParseFormat);
	if (typeof timestamp === "number") {
		return dayjs(timestamp);
	}
	if (typeof timestamp === "string") {
		const format1 = dayjs(timestamp, "ddd, DD MMM YYYY HH:mm:ss Z").format();
		const format2 = dayjs(timestamp, "YYYY-MM-DDTHH:mm:ss.000ZZ").format();
		if (isValidDate(format1)) {
			return format1;
		}
		if (isValidDate(format2)) {
			return format2;
		}
		return dayjs(timestamp);
	}
	if (dayjs.isDayjs(timestamp)) {
		return timestamp;
	}

	return dayjs();
};

const formatToken = computed(() => {
	if (props.format) {
		return props.format;
	}

	if (props.timestamp) {
		const date = selectDate(props.timestamp);
		return dayjs(date).diff(dayjs(), "d") >= 1 ? "DD[:]HH[:]mm" : "HH[:]mm[:]ss";
	}

	return "HH[:]mm[:]ss";
});

const { durationLeft, durationExceeded, reset } = useCountdown({
	timestamp: selectDate(props.timestamp || ""),
	formatToken: formatToken.value,
	onCountdownStop: () => {
		emit("onTimerEnd");
	}
});

const duration = computed(() => {
	if (typeof durationLeft.value === "string") {
		return durationLeft.value.split("");
	}
	return durationLeft.value;
});

const flipedStates = ref(duration.value.map(() => false));

const unwatchCounter = watch(
	duration,
	(val, oldVal) => {
		val.forEach((item, index) => {
			if (item !== oldVal[index]) {
				flipedStates.value[index] = true;
				setTimeout(() => {
					flipedStates.value[index] = false;
				}, 400);
			}
		});
	},
	{ deep: true }
);

const unwatchResetCounter = watch(
	() => props.timestamp,
	(newTimestamp) => {
		reset(selectDate(newTimestamp || ""), formatToken.value);
	}
);

onUnmounted(() => {
	unwatchCounter();
	unwatchResetCounter();
});
</script>

<template>
	<div v-if="!durationExceeded" :class="['wrapper', size, { mode: isBadge }]">
		<AText v-for="(item, i) in durationLeft" :key="i" :class="['box', 'color-neutral-15', { spacer: item === ':' }]">
			<template v-if="item === ':'">
				{{ item }}
			</template>
			<template v-else>
				<div :class="['leaf', { flipped: flipedStates[i] }]">
					<figure class="rear">{{ item }}</figure>
					<figure class="front">{{ item }}</figure>
				</div>
				<div class="top">{{ item }}</div>
				<div class="bottom">{{ item }}</div>
			</template>
		</AText>
	</div>
</template>
<style lang="scss">
:root {
	--m-counter-width: 20px;
	--m-counter-height: 32px;
	--m-counter-font-size: 20px;
}
</style>
<style lang="scss" scoped>
.wrapper {
	display: inline-flex;
	white-space: nowrap;
	background: v-bind(background);
	border-radius: 8px;

	&.lg {
		gap: 4px;
		padding: 8px 12px;

		&.mode {
			padding-top: 16px;
		}
	}

	&.md {
		--m-counter-width: 15px;
		--m-counter-height: 24px;
		--m-counter-font-size: 14px;
		gap: 2px;
		padding: 4px;

		&.mode {
			padding-top: 12px;
		}
	}
}

.box {
	height: var(--m-counter-height);
	font-size: var(--m-counter-font-size);
	text-shadow: 0px 1px 2px rgba(var(--custom-rgb-8), 0.25);
	position: relative;
	text-align: center;
	perspective: var(--m-counter-height);
}

.box:not(.spacer) {
	width: var(--m-counter-width);
	border-radius: 4px;

	.top,
	.front {
		background: linear-gradient(180deg, #dadee9 0%, #f6f8ff 100%);
	}

	.bottom,
	.rear {
		background: linear-gradient(180deg, #c2c5ce 0%, #f0f0f1 100%);
	}

	.top,
	.bottom {
		overflow: hidden;
		position: absolute;
		width: var(--m-counter-width);
		height: calc(var(--m-counter-height) / 2);
	}

	.leaf {
		z-index: 1;
		position: absolute;
		width: var(--m-counter-width);
		height: var(--m-counter-height);
		transform-style: preserve-3d;
		transition: transform 0s;
	}

	.leaf.flipped {
		transform: rotateX(-180deg);
		transition: all 0.3s ease-in-out;
	}

	.front,
	.rear {
		overflow: hidden;
		position: absolute;
		width: var(--m-counter-width);
		height: calc(var(--m-counter-height) / 2);
		margin: 0;
		transform: rotateX(0deg);
		backface-visibility: hidden;
	}

	.front {
		line-height: var(--m-counter-height);
		border-radius: 4px 4px 0px 0px;
	}

	.rear {
		line-height: 0px;
		border-radius: 0px 0px 4px 4px;
		transform: rotateX(-180deg);
	}

	.top {
		line-height: var(--m-counter-height);
		border-radius: 4px 4px 0px 0px;
	}

	.bottom {
		bottom: 0;
		line-height: 0px;
		border-radius: 0px 0px 4px 4px;
	}
}

.spacer {
	color: var(--overlay-light);
}
</style>
